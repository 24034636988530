import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const SoftwareTestingProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Software testing services that drive product quality &{' '}
        <span className="heading-red">customer satisfaction</span>
      </>
    ),
    bannerContent: (
      <>
        Confidently deliver software faster with a talented team of testers and
        robust QA processes
      </>
    ),
    BannerAlt: 'Software testing services',
    buttonName: 'Let’s discuss your project',
    buttonLink: '/contact/',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform for
        <br />
        <span className="highlight-head">Software Testing Services?</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            We offer software testing services focused on delivering quality
            products. Our testing best practices like "test early, test often"
            and "proactive planning of software test cycles" have made us come a
            long way in serving your software testing needs. Our highly skilled
            quality assurance engineers are experienced in testing end-to-end
            workflows and executing test case scenarios. In addition, our{' '}
            <span className="color-through animated">dedicated teams</span>{' '}
            ensure independent testing for your applications based on smart
            techniques and the latest technology.
            <br /> <br />
            We employ world-class software testing practices and improve overall
            time-to-market and quality. We aim to provide higher test coverage
            and accurate deliverables. What differentiates us from others is
            that we create testing guidelines for organizations that aim at cost
            efficiency, optimized use of QA resources, and reduced risk of
            failure. Hence, we provide guaranteed customer satisfaction rather
            than just focussing on delivering tested products.
          </>
        ),
      },
      {
        detailsList: [
          'Optimized resource utilization',
          'Faster time-to-market',
          'Aligned with business needs',
          'Mitigated technical risks',
          'Consistent agile-driven software testing approach',
          'Cost-effective testing techniques',
          'Better test coverage and ROI analysis',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceofferingsProps: {
    title: (
      <>
        <span className="highlight-head">Software Testing</span> Services
      </>
    ),
    Serviceofferings: [
      {
        Title: 'Test automation services',
        para: (
          <>
            We offer full-fledged test automation services, from{' '}
            <strong>planning and defining testing scope</strong> to selecting a
            tool and maintaining a test automation environment. Our automation
            architects define the complexity levels of test automation
            architecture before integrating it into CI/CD pipeline and aligning
            test automation strategy to the development phases. <br /> <br />
            You can rely on us by leveraging our test automation services and
            get rid of manual regression testing processes. This will save a lot
            of time for your team while significantly ensuring test flexibility,
            test quality, and responsiveness.
            <br />
            <br />
            Our test automation services includes:{' '}
          </>
        ),
        typeList: [
          {
            title: 'Test automation strategy',
          },
          {
            title: 'Environment setup and configuration',
          },
          {
            title: (
              <>
                Automated{' '}
                <span className="color-through animated">UI testing</span>
              </>
            ),
          },
          {
            title: 'Test data generation',
          },
          {
            title: (
              <>
                <span className="color-through animated">
                  API testing services
                </span>
              </>
            ),
          },
          {
            title: 'CI/CD pipeline implementation',
          },
        ],
        buttonLink: '/services/test-automation/',
        buttonName: 'LEARN MORE',
        LinkType: EXTERNAL_LINK,
      },
      {
        Title: 'Microservices testing',
        para: (
          <>
            Our microservices testing services first decide on the software
            testing KPIs such as granularity of test cases, run time, test
            scripts maintainability, etc in the design phase of software
            testing. The following phases involve assigning{' '}
            <strong>QA managers</strong> and test automation architects for
            prioritizing the test scenarios for automation and preparing a test
            strategy plan. <br />
            <br />
            Thus, you will have an entire team of microservices testing. It
            includes managing the process to set up the test environment, test
            data generation, and designing the test cases. <br />
            <br />
            Our microservices testing plan includes (but not limited to) the
            following set of testing types:
          </>
        ),
        typeList: [
          { title: 'Unit testing' },
          { title: 'Integration testing' },
          { title: 'Component and contract testing' },
          {
            title: (
              <>
                <span className="color-through animated">
                  Regression testing
                </span>
              </>
            ),
          },
          { title: 'Performance testing' },
        ],
        buttonLink: '/services/microservice-testing/',
        buttonName: 'LEARN MORE',
        LinkType: EXTERNAL_LINK,
      },
      {
        Title: 'API testing',
        para: (
          <>
            As modern architectures evolve, organizations need to focus more on
            end-to-end functionalities of APIs. It involves cloud services,
            mobile solutions, and other business applications that follow
            specific protocols such as XML, SOAP, REST, and others. <br />
            <br />
            Our <strong>QA managers</strong> have domain expertise in various
            API protocols and proficient knowledge and experience of end-to-end
            application validation. As a leading software testing company, we
            use the latest tools for API testing for functional, security, and
            load testing purposes. <br />
            <br />
            We give our clients mandatory API testing services as follows:
          </>
        ),
        typeList: [
          {
            title: 'Unit testing services for assessing API functionalities',
          },
          {
            title:
              'Load testing to validate performance under various levels of user requests/ load',
          },
          {
            title: 'Runtime error detection',
          },
          {
            title: 'Web UI testing',
          },
        ],
        buttonLink: '/services/api-testing/',
        buttonName: 'LEARN MORE',
        LinkType: EXTERNAL_LINK,
      },
      {
        Title: 'Performance testing',
        para: (
          <>
            Performance testing remains at the forefront of Simform's software
            QA testing services as it determines the overall application
            stability. It covers many applications, including complex
            transactions systems, client-server apps, cloud databases, and more.
            <br />
            <br />
            We provide a <strong>full-proof</strong> performance testing
            solution that checks everything that makes apps responsive,
            accessible, and scalable for users.
            <br />
            <br />
            Prominent factors in performance testing include load testing,
            stress handling, endurance checks, benchmarking, and other critical
            deliverables such as,
          </>
        ),
        typeList: [
          {
            title: 'Scalability testing',
          },
          {
            title: 'Stability testing',
          },
          {
            title: 'Load testing',
          },
          {
            title: 'Reusable performance test scripts',
          },
          {
            title: 'Analysis of test results',
          },
        ],
        buttonLink: '/services/performance-testing/',
        buttonName: 'LEARN MORE',
        LinkType: EXTERNAL_LINK,
      },
      {
        Title: 'Security testing',
        para: (
          <>
            Our security testing services aim to make applications secure and
            safe for users by addressing vulnerable security issues. Our
            application security testing strategy ensures that your data's
            confidentiality, integrity, and sensitivity are not compromised. In
            addition, our security testing engineers assure that your
            application meets the necessary security regulatory and compliance
            standards. <br />
            <br />
            Our experienced testing professionals help our customers identify
            critical areas, prioritize testing needs and align suitable
            strategies to accelerate the process and safeguard apps from
            potential threats. <br />
            <br />
            Here's what we evaluate and offer as security testing services:{' '}
          </>
        ),
        typeList: [
          {
            title: 'Application protocol interfaces',
          },
          {
            title: 'Network services',
          },
          {
            title: 'Penetration testing',
          },
          {
            title: 'Vulnerability assessment',
          },
          {
            title: 'Full-stack web and mobile apps security testing',
          },
          {
            title: 'Infrastructure audit',
          },
          {
            title: 'Compliance testing',
          },
        ],
        buttonLink: '/services/security-testing/',
        buttonName: 'LEARN MORE',
        LinkType: EXTERNAL_LINK,
      },
    ],
  },
  customDevProps: {
    serviceHeading: (
      <>
        <span className="highlight-head">Software Testing Services</span> for
        Different Applications Types
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: <>Web application testing</>,
        serviceParagraph: (
          <>
            Detects issues related to the frontend and backend of all types of
            web apps by examining application’s overall performance and
            user-friendliness.
          </>
        ),
        serviceIcon: images.sautomationTesting,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'SaaS testing',
        serviceParagraph: (
          <>
            Examines the integrity of your SaaS applications by conducting
            security and privacy-related checks, validating business logic, and
            data integration checks.
          </>
        ),
        serviceIcon: images.smanualTestingServices,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '3',
        serviceHeading: 'Mobile app testing',
        serviceParagraph: (
          <>
            Core focus of <strong>mobile app</strong> testing services include
            performance, compatibility, usability, and functional testing types
            to deliver a rich experience through your mobile application.
          </>
        ),
        serviceIcon: images.smobileAppTestingServices,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Desktop application testing',
        serviceParagraph: (
          <>
            Ensures that every function of your desktop application works just
            fine without any unexpected outcomes by conducting tests that assess
            usability, functionality, and stress handling capability.
          </>
        ),
        serviceIcon: images.iconProgressiveWeb,
        serviceAlt: 'Progressive',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Data warehouse testing',
        serviceParagraph: (
          <>
            Builds and executes comprehensive test cases to ensure that the data
            is reliable, accurate, and consistent with the business's data
            framework.
          </>
        ),
        serviceIcon: images.sdevOpsAgileService,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: <>Cloud app testing</>,
        serviceParagraph: (
          <>
            Helps you launch your cloud application by achieving faster
            time-to-market and data accessibility using standardized testing
            practices with proven testing frameworks.
          </>
        ),
        serviceIcon: images.scloudApp,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    buildAppPara: (
      <>
        Embrace quality engineering practices and get the business outcomes that
        you always wanted! The biggest benefit of working with Simform is that
        you’ll get a complete package of software testing services. We'll take
        care of creating a <strong>test plan</strong>, assembling the right
        methodologies, and detecting defects that significantly improve{' '}
        <strong>software quality</strong> and decrease the time-to-market by at
        least 25%.{' '}
      </>
    ),
    detailsList: [
      <>
        <strong>Proven testing experts:</strong> Our end-to-end software test
        services, led by passionate software testers, help you plan, build the
        right team, and assist your developers in focusing on building quality
        products.
      </>,
      <>
        <strong>End-to-End test coverage:</strong> We offer you complete test
        coverage that surfaces application bugs and defects that are difficult
        to detect. We perform all types of testing like Functional, GUI testing,
        Usability, Security, <strong>Accessibility testing,</strong> etc.
      </>,
      <>
        <strong>Complete transparency with KPIs:</strong> We align QA processes
        KPIs precisely with your <strong>business needs</strong> and project
        goals. You'll have full control and access to QA reporting, including
        test results, test coverage, quality trends, sign-off reports, and more.
      </>,
      <>
        <strong>Test automation for faster deliveries:</strong> Our software
        testing teams are armed with DevOps and automation tools that automate
        the most critical and time-consuming operations that lead to faster
        deliveries.
      </>,
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Recent <span className="highlight-head">Case Studies</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        Customer <span className="highlight-head">Testimonials</span>
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },

  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">QA</span> Process
      </>
    ),
    processPara: (
      <>
        We integrate <span className="color-through">Agile methodology</span> in
        our QA process. It is a continuous process rather than being sequential
        in which the development is aligned with customer requirements. The
        testing begins at the start of the project and there is an ongoing
        collaboration between testing and development.{' '}
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'pale-lilac-three',
        workingBenefitsTitle: 'User story evaluation',
        workingBenefitsContent: (
          <>
            The testing team works closely to understand your requirements. They
            follow the <strong>prioritized requirement practice</strong>: With
            each iteration, the team takes the most essential requirements
            remaining from the work stack to test on.{' '}
          </>
        ),
        workingBenefitsPatern: images.processflow1,
        benefitImgAlt: 'User story',
      },
      {
        workingBenefitsClass: 'pale-mauve-three',
        workingBenefitsTitle: 'Create a test plan ',
        workingBenefitsContent: (
          <>
            A <strong>detailed test plan</strong> is created that describes the
            scope of testing for the sprint. <br /> <br /> It contains - systems
            and configurations that need to be tested, non-functional
            requirements like code quality, test approach—
            <strong>traditional, exploratory, automation</strong>—or a mix,
            documentation to refer, test environment requirements and setup,
            etc.{' '}
          </>
        ),
        workingBenefitsPatern: images.processflow2,
        benefitImgAlt: 'Create a test plan',
      },
      {
        workingBenefitsClass: 'light-sky-blue-two',
        workingBenefitsTitle: 'Designing test cases',
        workingBenefitsContent: (
          <>
            The QA team writes test cases according to the test plan and unites
            them into a <strong>test case document.</strong> For each test case,
            we specify its objective, the initial state of the software, the
            input sequence and the expected outcome.
            <br />
            <br /> It is a three-step process: <br />
            <br />
          </>
        ),
        workingBenefitsList: [
          'Identify test conditions',
          'Design test cases – determine ‘how’ test conditions are to be exercised;',
          'Build test cases – implementation of the test cases (scripts, data, etc.).',
        ],
        workingBenefitsPatern: images.processflow3,
        benefitImgAlt: 'Designing test cases',
      },
      {
        workingBenefitsClass: 'pale-four',
        workingBenefitsTitle: 'Implementing tests',
        workingBenefitsContent: (
          <>
            Here, <strong>unit and integration tests</strong> are built. Unit
            testing helps check correctness for individual units of code. When a
            software test case covers more than one unit, it is considered an
            integration test. <br /> <br /> During unit testing, production{' '}
            <strong>code functions are executed</strong> in a test environment
            with simulated input. The output of the function is then compared
            against expected output for that input.
          </>
        ),
        workingBenefitsPatern: images.processflow4,
        benefitImgAlt: 'Implementing tests',
      },
      {
        workingBenefitsClass: 'light-periwinkle-two',
        workingBenefitsTitle: 'Execute all of the test cases',
        workingBenefitsContent: (
          <>
            Executing all the test cases can be done either manually or with{' '}
            <strong>automation tools.</strong> The order in which the test cases
            are executed is critical here. The most important test cases are
            executed first.
            <br />
            <br /> It is common practice to schedule integration tests just
            after delivery sprints. We run a{' '}
            <strong>System Integration Test</strong>, focusing on how the app
            components work. So while app-specific bugs will primarily be
            reported during the sprints, functional end-to-end bugs will crop up
            during the integration test.
          </>
        ),
        workingBenefitsPatern: images.processflow5,
        benefitImgAlt: 'Execute test cases',
      },
      {
        workingBenefitsClass: 'pale-sky-blue-two',
        workingBenefitsTitle: 'Manual & exploratory testing',
        workingBenefitsContent: (
          <>
            Testers are assigned <strong>loosely defined tasks</strong> to
            complete in the software. This means you can learn a lot about the
            way people use your product in the wild. <br /> <br /> Testers{' '}
            <strong>identify the functionality</strong> of an application by
            exploring the application. The testers try to learn the application,
            and design & execute the test plans according to their findings.{' '}
          </>
        ),
        workingBenefitsPatern: images.processflow6,
        benefitImgAlt: 'Manual & exploratory testing',
      },
      {
        workingBenefitsClass: 'pale-lilac-four ',
        workingBenefitsTitle: 'Test closure',
        workingBenefitsContent: (
          <>
            You get a <strong>test summary report</strong> describing the
            testing results. This activity has the purpose of checking the
            results against the completion criteria specified in the test plan.
            Let’s look at the components of exit criteria in general:
            <br />
            <br />
            <ul>
              <li>100% requirements coverage</li>
              <li>The minimum pass rate percentage</li>
              <li>All critical defects to be fixed</li>
            </ul>
          </>
        ),
        workingBenefitsPatern: images.processflow7,
        benefitImgAlt: 'Testing report',
      },
      {
        workingBenefitsClass: 'pale-aqua',
        workingBenefitsTitle: 'Continuous delivery',
        workingBenefitsContent: (
          <>
            Continuous delivery leverages all of the above testings to{' '}
            <strong>create a seamless pipeline</strong> that automatically
            delivers completed code tasks. If the code passes the testing, It
            will be automatically merged and deployed to production. If however,
            the code fails the tests. The code will be rejected and the
            developer automatically notified of steps to correct. <br /> <br />{' '}
            If however, the code fails the tests. The code will be rejected and
            the developer automatically notified of steps to correct.
          </>
        ),
        benefitImgAlt: 'Continuous delivery',
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle: 'What types of testing do you perform?',
        faqPara: (
          <>
            We perform the following types of testing as per project
            requirements:
            <br /> <br />
            <ul>
              <li>Functional Testing</li>
              <li>Usability Testing</li>
              <li>UI Testing</li>
              <li>Acceptance Testing</li>
              <li>Cross-Platform Testing</li>
              <li>Configuration and Compatibility Testing</li>
              <li>End-to-End Testing</li>
              <li>Load and Stress Testing</li>
              <li>Integration Testing</li>
              <li>Security Testing</li>
              <li>Data Migration Testing</li>
              <li>Localization and Internationalization Testing</li>
            </ul>
          </>
        ),
      },

      {
        faqTitle: 'How do you use automation testing?',
        faqPara: (
          <>
            We automate tests for repeatability. We automate a test because we
            need to execute the same tests over and over again. Would you want
            to automate a test if you were only going to run it once and forget
            about it? Of course not! The time and effort that you spend on
            automating the test, you could have executed it manually.
            <br /> <br />
            Implementing a robust automation testing solution is no mean task
            and proves challenging for many companies – our dynamic and highly
            experienced team is amongst the top in automation testing services.
            With a holistic focus on your business, we strategically design test
            processes, set up robust automated scripts, create QA automation
            framework and run Selenium & Mobile Apps automated test scripts for
            consistent and reliable coverage overall.
          </>
        ),
      },

      {
        faqTitle: 'How do you do QA using functional testing?',
        faqPara: (
          <>
            Functional testing is a process of verifying that a system performs
            as expected when its features are exercised by another system or
            directly by a user. The entire range of the development process
            comes under the purview of functionality verification.
            <br /> <br />
            - Unit tests should start at the very beginning to ensure that each
            block of code performs its intended manipulation of inputs into
            desired outputs for the next module.
            <br />- Integration tests assure that the unit modules connect each
            other as expected and convey data and commands throughout the system
            per the specifications to which it was built.
            <br />- Sanity testing checks verify that modifications and fixes
            applied to the code body don’t have unexpected side effects in,
            apparently, unrelated parts of the system. <br />
            - Regression tests verify that later feature additions and bug fixes
            don’t undo previous efforts or interact with them to cause wholly
            new problems.
            <br />- Usability acceptance is the actual operation of the system
            in the context in which it was designed to be used and is the
            gateway to deployment.
          </>
        ),
      },

      {
        faqTitle: 'How do you create test plans for Agile development?',
        faqPara: (
          <>
            In an agile environment, where we work in short sprints or
            iterations, each sprint is focused on only a few requirements or
            user stories, so it is natural that documentation may not be as
            extensive, in terms of both number and content.
            <br /> <br />
            We should not have an extensive test plan in agile projects for each
            sprint due to time constraints, but we do require a high-level agile
            test plan as a guideline for agile teams. The purpose of the agile
            test plan document is to list best practices and some form of
            structure that the teams can follow. Remember, agile does not mean
            unstructured.
          </>
        ),
      },

      {
        faqTitle:
          'How do you ensure developer side testing for quality software?',
        faqPara: (
          <>
            Our developers perform unit testing during writing the code to
            ensure that code works well.
            <br /> <br />
            <strong> What is unit testing?</strong>
            <br />
            <br />
            A test verifying methods of a single class. Any dependencies
            external to the class are ignored or mocked out. Note that some
            single class tests also qualify as feature tests in a few cases,
            depending on the scope of the “feature” under test.
            <br /> <br />
            We don’t consider Unit tests as 'testing-costs. We think that Unit
            tests should be part of 'core' engineering & a part of development.
            Not a task that's added to testing costs. If you aren't writing unit
            tests (irrespective of whether its TDD or not), you are not
            developing/engineering your product right. You are only building a
            stack of cards _hoping_ that it wouldn't collapse at some point in
            the future.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/test-automation-best-practices/',
        featurecardTitle:
          'Test Automation Best Practices and Tips for QA Managers',
      },
      {
        blogLink:
          'https://www.simform.com/blog/unit-testing-vs-functional-testing/',
        featurecardTitle:
          'Unit Testing vs Functional Testing: A Detailed Comparison',
      },
      {
        blogLink: 'https://www.simform.com/blog/mobile-app-testing/',
        featurecardTitle:
          'Mobile App Testing: Challenges, Types and Best Practices',
      },
    ],
  },
}
